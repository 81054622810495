import ai from '../ai.json'

var codingAiPageArray =  ai.codingAi

const listElement = codingAiPageArray.map((element) => 

          <a className='text-decoration-none' onClick={()=>{openWebIntent(element.url)}}>
            <div className='border border-primary m-3 p-3 bg-black text-white rounded opacity-75'>
              <b>
                <center>
                {element.name}
                </center>
                
              </b>      
            </div>
          </a>
);




export default function CodingAi() {
    return(
        <>
        <div>
                  {listElement}
        </div>
        </>
    )
}

function openWebIntent(url){
    if(url != null){
      window.Android.openWebIntent(url);
    }
}