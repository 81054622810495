import ai from '../ai.json'
import { Link } from 'react-router-dom';


var homePageArray =  ai.home

const listElement = homePageArray.map((element) => 

          <Link to={element.url} className='text-decoration-none'>
            <div className='border border-primary m-3 p-3 bg-black text-white rounded opacity-75'>
              <b>
                <center>
                {element.name}
                </center>
                
              </b>      
            </div>
          </Link>
);



export default function Home() {
    return(
        <>
        <div>
                  {listElement}
        </div>
        </>
    )
}
