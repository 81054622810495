import ai from '../ai.json'

var photoAiPageArray =  ai.photoAi

const listElement = photoAiPageArray.map((element) => 

          <a onClick={()=>{openWebIntent(element.url)}} className='text-decoration-none'>
            <div className='border border-primary m-3 p-3 bg-black text-white rounded opacity-75'>
              <b>
                <center>
                {element.name}
                </center>
                
              </b>      
            </div>
          </a>
);





export default function PhotoAi() {
    return(
        <>
        <div>
                  {listElement}
        </div>
        </>
    )
}


function openWebIntent(url){
  if(url != null){
    window.Android.openWebIntent(url);
  }
}