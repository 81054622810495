import logo from './logo.svg';
import './App.css';

import {createBrowserRouter,createRoutesFromElements,Route,RouterProvider} from "react-router-dom";

import Home from './comp/Home'
import SearchAi from './comp/SearchAi'
import CodingAi from './comp/CodingAi'
import ContentWritingAI from './comp/ContentWritingAi'
import PhotoAi from './comp/PhotoAi'



const router = createBrowserRouter(
  createRoutesFromElements(
    
    <>

    <Route path="/" element={<Home />} />

    <Route path="/search-ai" element={<SearchAi />} />
    <Route path="/coding-ai" element={<CodingAi />} />
    <Route path="/content-writing-ai" element={<ContentWritingAI />} />
    <Route path="/photo-ai" element={<PhotoAi />} />
   

   </>
  )
);




            function App() {
                return(

                  <RouterProvider router={router} />
                  
                )


}






export default App;
